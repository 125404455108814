import { styled } from '@fis/util-theme';
import { render } from 'react-dom';
import * as Icon from './event-icons';
import { useState } from 'react';

/* -------------------------------------------------------------------------------------------------
 * Container
 * ------------------------------------------------------------------------------------------------*/

const Container = styled('div', {
  background:
    'radial-gradient(107.24% 228.42% at 100% 100%, #1E2330 0%, #31394F 100%)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '$2',

  variants: {
    size: {
      base: {
        minWidth: '96px',
        height: '88px',
      },
      lg: {
        minWidth: '200px',
        height: '200px',

        '& svg': {
          transform: 'scale(2, 2)',
          opacity: '.6',
        },
      },
    },
  },
});

/* -------------------------------------------------------------------------------------------------
 * Image
 * ------------------------------------------------------------------------------------------------*/

const EventImageFromUrl = styled('div', {
  width: '100%',
  height: '100%',
  backgroundSize: 'cover',
  borderRadius: '$2',
});

/* -------------------------------------------------------------------------------------------------
 * Exports
 * ------------------------------------------------------------------------------------------------*/

type EventImageProps = {
  imageUrl: string;
  eventType: string[];
  imageSize?: string;
};

const EventImage = (props: EventImageProps) => {
  const { imageUrl, eventType, imageSize } = props;
  const [imageError, setImageError] = useState(false);

  const resolveEventTypeIcon = (eventTypes) => {
    if (eventTypes.length > 1 || eventTypes.length === 0) {
      return <Icon.NoEventTypeIcon />;
    }

    switch (eventTypes[0]) {
      case 'Live Lecture':
        return <Icon.LiveLectureIcon />;
      case 'Community':
        return <Icon.CommunityIcon />;
      case 'Office Hours':
        return <Icon.OfficeHoursIcon />;
      case 'Orientation':
        return <Icon.OrientationIcon />;
      case 'Guided Practice':
        return <Icon.GuidedPracticeIcon />;
      case 'Career':
        return <Icon.CareerIcon />;
      default:
        return <Icon.NoEventTypeIcon />;
    }
  };

  if (imageUrl && !imageError) {
    return (
      <Container size={imageSize === 'lg' ? 'lg' : 'base'}>
        <EventImageFromUrl
          css={{ backgroundImage: `url(${imageUrl})` }}
          onError={() => {
            setImageError(true);
          }}
        />
      </Container>
    );
  }

  return (
    <Container size={imageSize === 'lg' ? 'lg' : 'base'}>
      {resolveEventTypeIcon(eventType)}
    </Container>
  );
};

export { EventImage };
