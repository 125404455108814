export const OfficeHoursIcon = () => (
  <svg
    width="36"
    height="34"
    viewBox="0 0 36 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="OfficeHoursIcon"
  >
    <g filter="url(#filter0_d_2214_12877)">
      <path
        d="M6.25894 18.5938C5.14076 16.7088 4.74916 14.4805 5.15768 12.3272C5.56621 10.1739 6.74675 8.2438 8.47764 6.89933C10.2085 5.55486 12.3707 4.8885 14.5581 5.02539C16.7455 5.16227 18.8078 6.09298 20.3575 7.64274C21.9073 9.19251 22.838 11.2547 22.9749 13.4421C23.1118 15.6296 22.4455 17.7917 21.101 19.5226C19.7565 21.2535 17.8264 22.4341 15.6731 22.8426C13.5199 23.2511 11.2915 22.8595 9.40648 21.7414L9.40651 21.7413L6.29808 22.6294C6.16947 22.6661 6.03338 22.6678 5.90391 22.6343C5.77443 22.6007 5.65628 22.5332 5.56171 22.4386C5.46713 22.344 5.39957 22.2259 5.36602 22.0964C5.33247 21.9669 5.33415 21.8308 5.37089 21.7022L6.25902 18.5938L6.25894 18.5938Z"
        stroke="#7DD3FC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5078 22.9865C13.9502 24.2425 14.6657 25.3848 15.6027 26.331C16.5397 27.2772 17.6749 28.0039 18.9265 28.4586C20.1781 28.9133 21.5149 29.0849 22.8408 28.9608C24.1666 28.8367 25.4485 28.4201 26.594 27.7411L26.5939 27.741L29.7024 28.6291C29.831 28.6658 29.9671 28.6675 30.0965 28.634C30.226 28.6004 30.3442 28.5328 30.4387 28.4383C30.5333 28.3437 30.6009 28.2255 30.6344 28.0961C30.668 27.9666 30.6663 27.8305 30.6296 27.7019L29.7414 24.5935L29.7415 24.5935C30.5306 23.2627 30.9634 21.751 30.9982 20.2042C31.0329 18.6574 30.6684 17.1278 29.9399 15.7629C29.2113 14.3979 28.1433 13.2438 26.8388 12.4119C25.5344 11.5799 24.0375 11.0982 22.4927 11.0132"
        stroke="#7DD3FC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2214_12877"
        x="0.25"
        y="0.257812"
        width="35.5"
        height="33.4922"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.654902 0 0 0 0 0.870588 0 0 0 0 0.988235 0 0 0 0.7 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2214_12877"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2214_12877"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const OrientationIcon = () => (
  <svg
    width="40"
    height="46"
    viewBox="0 0 40 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2214_12841)">
      <path
        d="M36 25.4026C35.9955 21.4064 34.4974 17.5561 31.7998 14.6077C29.1022 11.6593 25.4 9.82577 21.4199 9.46696C21.9844 9.15252 22.4285 8.65961 22.6827 8.06552C22.9368 7.47143 22.9866 6.80979 22.8242 6.18437C22.6617 5.55896 22.2963 5.00515 21.7852 4.60982C21.2741 4.21449 20.6462 4 20 4C19.3538 4 18.7259 4.21449 18.2148 4.60982C17.7037 5.00515 17.3383 5.55896 17.1758 6.18437C17.0134 6.80979 17.0632 7.47143 17.3173 8.06552C17.5715 8.65961 18.0156 9.15252 18.5801 9.46696C15.4883 9.74242 12.5434 10.9113 10.1043 12.8311C7.66514 14.7508 5.83708 17.3387 4.84289 20.2792C3.8487 23.2197 3.7313 26.3859 4.50501 29.392C5.27872 32.398 6.91012 35.1141 9.2004 37.2093C11.4907 39.3044 14.3409 40.6881 17.4038 41.1918C20.4667 41.6955 23.61 41.2973 26.4506 40.0459C29.2912 38.7945 31.7064 36.7438 33.402 34.1438C35.0976 31.5437 36.0002 28.5067 36 25.4026ZM20 5.38654C20.3047 5.38655 20.6024 5.47694 20.8557 5.64629C21.1089 5.81564 21.3062 6.05633 21.4226 6.33787C21.539 6.61942 21.5692 6.92917 21.5094 7.2279C21.4497 7.52662 21.3026 7.8009 21.0868 8.016C20.8711 8.23109 20.5964 8.37734 20.2975 8.43622C19.9986 8.49509 19.6889 8.46395 19.4077 8.34674C19.1265 8.22952 18.8864 8.03151 18.7178 7.77776C18.5493 7.52401 18.4597 7.22594 18.4607 6.92129C18.4619 6.51383 18.6246 6.12347 18.9131 5.83578C19.2017 5.54809 19.5925 5.38654 20 5.38654ZM20 40.0241C17.1068 40.025 14.2784 39.1679 11.8725 37.5611C9.46651 35.9543 7.59114 33.6701 6.48355 30.9973C5.37597 28.3246 5.08594 25.3834 5.65014 22.5458C6.21435 19.7081 7.60744 17.1016 9.65321 15.0558C11.699 13.0101 14.3055 11.617 17.1431 11.0528C19.9807 10.4886 22.9219 10.7786 25.5947 11.8862C28.2674 12.9938 30.5517 14.8691 32.1585 17.2751C33.7652 19.681 34.6224 22.5095 34.6215 25.4026C34.6178 29.2794 33.0762 32.9963 30.3349 35.7375C27.5937 38.4788 23.8767 40.0205 20 40.0241Z"
        fill="#7DD3FC"
      />
      <path
        d="M24.9622 14.3561C24.8298 14.2971 24.6822 14.2813 24.5403 14.311C24.3984 14.3407 24.2695 14.4144 24.1719 14.5216L16.0938 23.413C15.9974 23.5226 15.9365 23.6587 15.9191 23.8036L14.6325 35.7507C14.6177 35.8955 14.6487 36.0413 14.7213 36.1675C14.7939 36.2937 14.9043 36.3938 15.0369 36.4538C15.1693 36.5128 15.3169 36.5286 15.4588 36.4989C15.6007 36.4692 15.7296 36.3956 15.8272 36.2884L23.9054 27.3923C24.0008 27.2822 24.0615 27.1463 24.08 27.0018L25.3666 15.0546C25.3804 14.9105 25.3489 14.7658 25.2764 14.6405C25.2039 14.5153 25.094 14.4159 24.9622 14.3561ZM22.7566 26.6296L16.2316 33.7887L17.2655 24.1758L23.7675 17.0167L22.7566 26.6296Z"
        fill="#7DD3FC"
      />
      <path
        d="M20.0231 27.3281C20.4678 27.3245 20.8975 27.167 21.2392 26.8825C21.581 26.598 21.8137 26.204 21.8979 25.7673C21.9821 25.3307 21.9125 24.8784 21.701 24.4872C21.4895 24.0961 21.1492 23.7901 20.7377 23.6215C20.3263 23.4528 19.8692 23.4317 19.4439 23.5618C19.0187 23.6919 18.6517 23.9653 18.4051 24.3353C18.1585 24.7054 18.0477 25.1494 18.0913 25.5919C18.135 26.0344 18.3305 26.4482 18.6446 26.7629C18.825 26.9444 19.0399 27.0879 19.2767 27.185C19.5134 27.2821 19.7673 27.3307 20.0231 27.3281ZM19.6325 25.0306C19.6836 24.979 19.7444 24.9382 19.8115 24.9106C19.8786 24.883 19.9506 24.8691 20.0231 24.8698C20.095 24.8692 20.1662 24.8831 20.2325 24.9108C20.2989 24.9384 20.3589 24.9792 20.4091 25.0306C20.5101 25.1337 20.5667 25.2723 20.5667 25.4166C20.5667 25.5609 20.5101 25.6995 20.4091 25.8026C20.3566 25.8536 20.2946 25.8937 20.2266 25.9207C20.1586 25.9478 20.0859 25.9611 20.0127 25.96C19.9395 25.959 19.8673 25.9435 19.8001 25.9145C19.7329 25.8855 19.6721 25.8436 19.6211 25.7911C19.5701 25.7386 19.5299 25.6766 19.5029 25.6085C19.4759 25.5405 19.4625 25.4679 19.4636 25.3947C19.4647 25.3215 19.4801 25.2493 19.5091 25.1821C19.5381 25.1149 19.5801 25.054 19.6325 25.003V25.0306Z"
        fill="#7DD3FC"
      />
      <path
        d="M19.9998 16.1709C20.1826 16.1709 20.3579 16.0983 20.4872 15.9691C20.6164 15.8398 20.6891 15.6645 20.6891 15.4817V12.4397C20.6891 12.2569 20.6164 12.0816 20.4872 11.9524C20.3579 11.8231 20.1826 11.7505 19.9998 11.7505C19.817 11.7505 19.6417 11.8231 19.5124 11.9524C19.3832 12.0816 19.3105 12.2569 19.3105 12.4397V15.4817C19.3105 15.6645 19.3832 15.8398 19.5124 15.9691C19.6417 16.0983 19.817 16.1709 19.9998 16.1709Z"
        fill="#7DD3FC"
      />
      <path
        d="M10.1023 24.7363H7.06035C6.87755 24.7363 6.70223 24.8089 6.57297 24.9382C6.44371 25.0675 6.37109 25.2428 6.37109 25.4256C6.37109 25.6084 6.44371 25.7837 6.57297 25.913C6.70223 26.0422 6.87755 26.1148 7.06035 26.1148H10.1023C10.2851 26.1148 10.4604 26.0422 10.5897 25.913C10.7189 25.7837 10.7915 25.6084 10.7915 25.4256C10.7915 25.2428 10.7189 25.0675 10.5897 24.9382C10.4604 24.8089 10.2851 24.7363 10.1023 24.7363Z"
        fill="#7DD3FC"
      />
      <path
        d="M20.0418 34.6523C19.859 34.6523 19.6837 34.725 19.5544 34.8542C19.4252 34.9835 19.3525 35.1588 19.3525 35.3416V38.3835C19.3525 38.5663 19.4252 38.7416 19.5544 38.8709C19.6837 39.0002 19.859 39.0728 20.0418 39.0728C20.2246 39.0728 20.3999 39.0002 20.5292 38.8709C20.6584 38.7416 20.7311 38.5663 20.7311 38.3835V35.3232C20.7263 35.1436 20.6515 34.973 20.5228 34.8477C20.394 34.7224 20.2215 34.6523 20.0418 34.6523Z"
        fill="#7DD3FC"
      />
      <path
        d="M32.9815 24.6904H29.9441C29.7613 24.6904 29.586 24.763 29.4568 24.8923C29.3275 25.0216 29.2549 25.1969 29.2549 25.3797C29.2549 25.5625 29.3275 25.7378 29.4568 25.8671C29.586 25.9963 29.7613 26.0689 29.9441 26.0689H32.9815C33.1643 26.0689 33.3396 25.9963 33.4689 25.8671C33.5981 25.7378 33.6707 25.5625 33.6707 25.3797C33.6707 25.1969 33.5981 25.0216 33.4689 24.8923C33.3396 24.763 33.1643 24.6904 32.9815 24.6904Z"
        fill="#7DD3FC"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2214_12841"
        x="0"
        y="0"
        width="40"
        height="45.4038"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.653762 0 0 0 0 0.868642 0 0 0 0 0.989511 0 0 0 0.7 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2214_12841"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2214_12841"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const GuidedPracticeIcon = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2214_12851)">
      <path
        d="M21 21L24 18L21 15"
        stroke="#7DD3FC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2929 5.20711L5.20711 18.2929C4.81658 18.6834 4.81658 19.3166 5.20711 19.7071L18.2929 32.7929C18.6834 33.1834 19.3166 33.1834 19.7071 32.7929L32.7929 19.7071C33.1834 19.3166 33.1834 18.6834 32.7929 18.2929L19.7071 5.20711C19.3166 4.81658 18.6834 4.81658 18.2929 5.20711Z"
        stroke="#7DD3FC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 22V21C14 20.2044 14.3161 19.4413 14.8787 18.8787C15.4413 18.3161 16.2044 18 17 18H24"
        stroke="#7DD3FC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2214_12851"
        x="0.164062"
        y="0.164062"
        width="37.6719"
        height="37.6719"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.654902 0 0 0 0 0.870588 0 0 0 0 0.988235 0 0 0 0.7 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2214_12851"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2214_12851"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const CommunityIcon = () => (
  <svg
    width="40"
    height="30"
    viewBox="0 0 40 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2214_12824)">
      <path
        d="M19.9999 14.3591C17.1263 14.3591 14.8203 12.0531 14.8203 9.17955C14.8203 6.30596 17.1263 4 19.9999 4C22.838 4 25.1794 6.30596 25.1794 9.17955C25.1794 12.0531 22.838 14.3591 19.9999 14.3591ZM19.9999 5.41905C17.9422 5.41905 16.2394 7.12192 16.2394 9.17955C16.2394 11.2372 17.9422 12.94 19.9999 12.94C22.0575 12.94 23.7604 11.2372 23.7604 9.17955C23.7604 7.12192 22.0575 5.41905 19.9999 5.41905Z"
        fill="#7DD3FC"
      />
      <path
        d="M30.3233 14.7848C28.3012 14.7848 26.6338 13.1175 26.6338 11.0953C26.6338 9.07315 28.3012 7.40576 30.3233 7.40576C32.3455 7.40576 34.0129 9.07315 34.0129 11.0953C34.0129 13.1175 32.3455 14.7848 30.3233 14.7848ZM30.3233 8.82482C29.0817 8.82482 28.0528 9.85363 28.0528 11.0953C28.0528 12.337 29.0817 13.3658 30.3233 13.3658C31.565 13.3658 32.5938 12.337 32.5938 11.0953C32.5938 9.85363 31.565 8.82482 30.3233 8.82482Z"
        fill="#7DD3FC"
      />
      <path
        d="M9.67587 14.7848C7.65372 14.7848 5.98633 13.1175 5.98633 11.0953C5.98633 9.07315 7.65372 7.40576 9.67587 7.40576C11.698 7.40576 13.3654 9.07315 13.3654 11.0953C13.3654 13.1175 11.698 14.7848 9.67587 14.7848ZM9.67587 8.82482C8.4342 8.82482 7.40538 9.85363 7.40538 11.0953C7.40538 12.337 8.4342 13.3658 9.67587 13.3658C10.9175 13.3658 11.9464 12.337 11.9464 11.0953C11.9464 9.85363 10.9175 8.82482 9.67587 8.82482Z"
        fill="#7DD3FC"
      />
      <path
        d="M30.3589 22.6252C28.9398 22.6252 28.0175 22.5543 27.2725 22.4478L26.7048 22.3414V21.5964C26.7048 20.1419 26.2436 18.6873 25.3567 17.3747L25.1084 17.02L25.2858 16.6652C26.2791 14.6785 28.2303 13.4014 30.2879 13.4014C33.3389 13.4014 35.9642 16.1685 35.9996 19.4324C36.0351 22.6252 33.0196 22.6252 30.3589 22.6252ZM28.1239 21.1352C28.6915 21.2062 29.401 21.2062 30.3589 21.2062C33.8711 21.2062 34.6161 20.8869 34.6161 19.4324C34.5806 16.9845 32.5939 14.8204 30.3234 14.8204C28.9398 14.8204 27.5917 15.6364 26.7758 16.949C27.5917 18.2616 28.0175 19.6807 28.1239 21.1352Z"
        fill="#7DD3FC"
      />
      <path
        d="M9.67622 22.6252C7.01549 22.6252 4 22.6252 4 19.4324C4 16.1685 6.58978 13.4014 9.67622 13.4014C11.7338 13.4014 13.6851 14.6785 14.6784 16.6652L14.8558 17.02L14.6074 17.3747C14.0043 18.2971 13.2593 19.7516 13.2948 21.5609V22.3059L12.7627 22.4478C12.0177 22.5543 11.0953 22.6252 9.67622 22.6252ZM9.67622 14.8204C7.40573 14.8204 5.41905 16.9845 5.41905 19.4324C5.41905 20.8869 6.16406 21.2062 9.67622 21.2062C10.6341 21.2062 11.3436 21.1707 11.9112 21.1352C11.9822 19.7162 12.4079 18.2616 13.2239 16.9845C12.4079 15.6364 11.0598 14.8204 9.67622 14.8204Z"
        fill="#7DD3FC"
      />
      <path
        d="M20.0001 25.9952C15.9912 25.9952 11.876 25.9952 11.876 21.5962C11.876 16.9842 15.6719 12.9399 20.0001 12.9399C24.3282 12.9399 28.1242 16.9842 28.1242 21.5962C28.1242 25.9952 24.0089 25.9952 20.0001 25.9952ZM20.0001 14.359C16.417 14.359 13.295 17.7293 13.295 21.5962C13.295 24.1505 14.8915 24.5762 20.0001 24.5762C25.1087 24.5762 26.7051 24.1505 26.7051 21.5962C26.7051 17.7293 23.5832 14.359 20.0001 14.359Z"
        fill="#7DD3FC"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2214_12824"
        x="0"
        y="0"
        width="40"
        height="29.9951"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.653762 0 0 0 0 0.868642 0 0 0 0 0.989511 0 0 0 0.7 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2214_12824"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2214_12824"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const CareerIcon = () => (
  <svg
    width="34"
    height="32"
    viewBox="0 0 34 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2214_12858)">
      <path
        d="M28.001 9H6.00098C5.44869 9 5.00098 9.44772 5.00098 10V26C5.00098 26.5523 5.44869 27 6.00098 27H28.001C28.5533 27 29.001 26.5523 29.001 26V10C29.001 9.44772 28.5533 9 28.001 9Z"
        stroke="#7DD3FC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 9V7C22 6.46957 21.7893 5.96086 21.4142 5.58579C21.0391 5.21071 20.5304 5 20 5H14C13.4696 5 12.9609 5.21071 12.5858 5.58579C12.2107 5.96086 12 6.46957 12 7V9"
        stroke="#7DD3FC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.0012 15.7886C25.354 17.8986 21.2137 19.0066 17.0002 18.9999C12.7873 19.0066 8.64768 17.899 5.00098 15.7896"
        stroke="#7DD3FC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 15H18.5"
        stroke="#7DD3FC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2214_12858"
        x="0.250977"
        y="0.25"
        width="33.5"
        height="31.5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.654902 0 0 0 0 0.870588 0 0 0 0 0.988235 0 0 0 0.7 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2214_12858"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2214_12858"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const LiveLectureIcon = () => (
  <svg
    width="34"
    height="35"
    viewBox="0 0 34 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2214_12866)">
      <path
        d="M28 8H6C5.44772 8 5 8.44772 5 9V24C5 24.5523 5.44772 25 6 25H28C28.5523 25 29 24.5523 29 24V9C29 8.44772 28.5523 8 28 8Z"
        stroke="#7DD3FC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 25L25 30"
        stroke="#7DD3FC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 25L9 30"
        stroke="#7DD3FC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 17V20"
        stroke="#7DD3FC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 15V20"
        stroke="#7DD3FC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 13V20"
        stroke="#7DD3FC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 8V5"
        stroke="#7DD3FC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2214_12866"
        x="0.25"
        y="0.25"
        width="33.5"
        height="34.5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.654902 0 0 0 0 0.870588 0 0 0 0 0.988235 0 0 0 0.7 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2214_12866"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2214_12866"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const NoEventTypeIcon = () => (
  <svg
    width="28"
    height="29"
    viewBox="0 0 28 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2214_12985)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5625 24.8333L11.7758 4H16.0856L8.87228 24.8333H4.5625ZM12.2061 24.8333L19.4194 4.00006H23.7292L16.5159 24.8333H12.2061Z"
        fill="#7DD3FC"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2214_12985"
        x="0.5625"
        y="0"
        width="27.167"
        height="28.8335"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.653762 0 0 0 0 0.868642 0 0 0 0 0.989511 0 0 0 0.7 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2214_12985"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2214_12985"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
